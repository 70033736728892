@import '../../../../settings.scss';

.implementation-module{
  background-color: $bColor;
  margin-top: 50px;
  padding-bottom: 50px;

  .implementation-steps{
    margin-top: 50px;
    .img-box{
      width: 100%;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}