@import '../../../settings.scss';

.gallery-module{

  .react-photo-gallery--gallery{
  }

  .margin{
    margin-right: 0px;
  }

  .img-txt-box{

    .img-box{
      position: relative;
      
      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .txt-box{
      position: absolute;
      top:300px;
      left:65px;
      width: 60%;

      h1{
        font-family: $fontOne;
        font-weight: bold;
        color: white;
        font-size: 75px;
      }
      p{
        margin-top: 25px;
        font-family: $fontOne;
        font-weight:700;
        color: white;
        font-size: 20px;
      }
    }
  }
  .gallery-box{
    margin-right:-5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .gallery{
      max-width: 80%;
      margin-bottom: 50px;

      img{
        padding: 6px;
        transition: transform 0.5s ease;

        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  .gallery-txt{
    margin: 50px 0 40px 0;
    text-align: center;
  }

  @media(max-width: 700px){
  
    .img-txt-box{
      .txt-box{
        top:100px;
        left:15px;
        width: 60%;

        h1{
        font-size: 25px;
        }
        p{
          display: none;
        }
      }
      .gallery-txt{
        margin: 15px 0 5px 0;
      }
  
    }
  }

}

