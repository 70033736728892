@import '../../../../settings.scss';

.navbar-module {
  position:fixed;
  top: 0;
  z-index: 2;
  padding-bottom: 20px;
  width: 100%;
  background-color: $bColor;

  .logo-and-navigate{
    margin-top: 20px;

    h1{
      margin-left: 15px;
      letter-spacing:5px;
    }

    a{
      text-decoration: none;
      color: black;
      
    }

    p {
      font-family: $fontOne; // Użycie zmiennej SCSS dla rodziny czcionek
      font-size: 18px;
      font-weight: bold;
      position: relative;
      display: inline-block; // Zapewnia, że pseudo-elementy są względem tego paragrafu
      transition: color 1s ease, font-size 0.5s ease; // Usunięcie border-bottom-color z animacji
    
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px; // Wysokość linii podkreślającej
        left: 0;
        bottom: -2px; // Pozycjonowanie tuż pod tekstem
        background-color: rgb(201, 189, 189); // Domyślny kolor linii podkreślającej
        transform: scaleX(0);
        transform-origin: bottom left;
        transition: transform 0.5s ease, background-color 0.5s ease; // Dodanie animacji koloru tła
      }
    
      &:hover {
        font-size: 20px; // Zmiana rozmiaru czcionki
    
        &::after {
          transform: scaleX(1); // Rozszerza pseudo-element do pełnej szerokości
          background-color: rgb(0, 0, 0); // Zmiana koloru linii podkreślającej
        }
      }
    }
    
    .active{
      p{
        border-bottom: 2px solid rgb(0, 0, 0) ;
        font-size: 20px;
      }
     
    }
  }


  @media(max-width: 900px){
    .navigate{
      display: none;
    }
  }
  @media(min-width: 900px){
    .mobile-viev{
      display: none;
    }
  }
}