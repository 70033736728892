@import '../../../settings.scss';

.contact-module{
  margin-bottom: 100px;
  h1{
    font-family: $fontOne;
    font-weight: bold;
    font-size: 55px;
  }
  p{
    font-family: $fontOne;
    font-weight:700;
    font-size: 20px;
  }
  .img-txt-box{

    .img-box{
      position: relative;
      
      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .txt-box{
      position: absolute;
      top:150px;
      left:65px;
      width: 60%;

      h1{
        font-weight: bold;
        color: white;
        font-size: 75px;
      }
      p{
        margin-top: 25px;
        color: white;
        font-size: 20px;
      }
    }
  }

  @media(max-width: 700px){
    .img-txt-box{
      .txt-box{
        top:80px;
        left:15px;
        width: 60%;
        h1{
          font-size: 20px;
        }
        p{
          display: none;
        }
      }
    }
    p{
      font-size: 15px;
    }
  }
}
