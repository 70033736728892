@import '../../../settings.scss';

.home-module{
  .img-and-txt-box{
    position: relative;

    .img-box{     

      img{
        width: 100%;
        height: auto;
      }  
    }
    .txt-box{
      position: absolute;
      top:200px;
      left:65px;
      width: 60%;

      h1{
        font-family: $fontOne;
        font-weight: bold;
        color: white;
        font-size: 75px;
      }
      p{
        margin-top: 25px;
        font-family: $fontOne;
        font-weight:700;
        color: white;
        font-size: 20px;
      }
    }
  }

  @media(max-width: 700px){
    
    .img-and-txt-box .txt-box{
      top: 25px;
      left: 15px;
      width: 70%;
      h1{
        font-size: 25px;
      }
      p{
        display: none;
      }
    }


    p{
      font-size: 15px;
    }
  }

}