@import '../../../../settings.scss';

.navbar-mobile-module{
  position: sticky;
  top: 0px;
  z-index: 9999;
  background-color: $bColor;
  display: flex;
  justify-content: space-around;

  .logo, .hamburger{
    margin: 13px 0 10px 0;
  }
  a{
    color: black;
    text-decoration: none;
  }
  
  .navigate {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px; // Ustawienie top na wysokość paska nawigacyjnego
    left: 0;
    width: 100%;
    height: calc(110vh - 60px); // Zmniejszenie wysokości o wysokość paska nawigacyjnego
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    
    transition: all 0.5s ease;
    z-index: 500;



    a {
      color: white; // Kolor tekstu
      text-decoration: none; // Brak podkreślenia
      margin:  0; // Marginesy dla odstępów
      font-size: 2.2em; // Większy rozmiar tekstu

      p{
        font-family: $fontOne;
        cursor: pointer;
      }

    }
    .active{
      font-weight: bold;
    }
  }
}